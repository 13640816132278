<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-user"></i>
          {{ $t('tabs.users.details') }}
        </h4>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="3" class="text-center">
            <b-avatar
                id="popover-avatar"
                size="100"
                variant="light-primary"
                :src="require(`@/assets/images/avatars/${user.avatar || '1'}.png`)"
            />
          </b-col>
          <b-col cols="9">
            <b-row>
              <b-col cols="4">
                <label>{{ $t('columns.firstName') }}*</label>
                <b-form-input v-model="user.firstName" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
              </b-col>
              <b-col cols="4">
                <label>{{ $t('columns.lastName') }}</label>
                <b-form-input v-model="user.lastName" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
              </b-col>
              <b-col cols="4">
                <label>{{ $t('columns.country') }}</label>
                <countries-select-box v-model="user.country"
                                      :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"
                />
              </b-col>
              <b-col cols="4">
                <label>{{ $t('columns.phone') }}</label>
                <b-input-group>
                  <b-form-input v-model="user.phone" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
                  <b-input-group-append>
                    <a
                        class="btn btn-outline-success" target="_blank"
                        v-b-tooltip :title="$t('operations.call')"
                    >
                      <i class="fa-solid fa-phone-volume text-primary"></i>
                    </a>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="4">
                <label>{{ $t('columns.internPhone') }}</label>
                <b-input-group>
                  <b-form-input v-model="user.internPhone"
                                :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"
                  />
                  <b-input-group-append>
                    <a
                        class="btn btn-outline-success" target="_blank"
                        v-b-tooltip :title="$t('operations.call')"
                    >
                      <i class="fa-solid fa-phone-volume text-primary"></i>
                    </a>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="4">
                <label>{{ $t('columns.email') }}</label>
                <b-input-group>
                  <b-form-input v-model="user.email" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
                  <b-input-group-append>
                    <a
                        class="btn btn-outline-success" target="_blank"
                        v-b-tooltip :title="$t('operations.sendMail')"
                    >
                      <i class="fa-solid fa-envelope"></i>
                    </a>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>


      <b-col cols="4" xl="3">
        <label>{{ $t('columns.address') }}</label>
        <b-form-input v-model="user.address" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.zip') }}</label>
        <b-form-input v-model="user.zip" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.city') }}</label>
        <b-form-input v-model="user.city" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.birthday') }}</label>
        <calendar-picker size="md" v-model="user.birthday" is-range mode="date"/>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-building-circle-exclamation"></i>
          {{ $t('tabs.users.management') }}
        </h4>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.creationTime') }}</label>
        <b-form-input v-model="user.creationTime" readonly/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.role') }}</label>
        <v-select
            v-model="user.roleName"
            :options="allRoles"
            label="name"
            :reduce="(role) => role.name"
        />
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.username') }}<span v-if="user.id == null">*</span></label>
        <b-form-input v-model="user.username" v-bind:readonly="user.id != null"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.password') }}<span v-if="user.id === null">*</span></label>
        <b-form-input v-model="user.password"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label class="invisible">{{ $t('columns.active') }}</label>
        <b-checkbox switch v-model="user.enabled">{{ $t('columns.active') }}</b-checkbox>
      </b-col>
    </b-row>
    <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 100%">
      <b-button class="d-inline-block" size="sm" variant="outline-warning">{{ $t('operations.reset') }}</b-button>&nbsp;
      <b-button class="d-inline-block" size="sm" variant="outline-success" @click="update">{{ $t('operations.save') }}
      </b-button>
    </div>
    <b-popover target="popover-avatar" triggers="hover" placement="right" size="xl">
      <div style="max-width: 500px">
        <b-row>
          <b-col v-for="index in 24" v-bind:key="index" class="p-1"  @click="onAvatarSelected(index)">
            <b-avatar
                class="selectable"
                :class="{'selected': user.avatar === (''+index), [`avatar-${index}`]: true}"
                size="60"
                variant="light-primary"
                :src="require(`@/assets/images/avatars/${index}.png`)"
            />
          </b-col>
        </b-row>
      </div>
    </b-popover>
  </div>
</template>

<script>
import User from '@/model/user/User'
import mixinPermissions from '@/components/mixin/mixinPermissions'
import { mapActions, mapGetters } from 'vuex'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import CalendarPicker from '@/components/widget/picker/calendarPicker'
import CountriesSelectBox from '@/components/widget/select/CountriesSelectBox'

export default {
  name: 'UserEditDetails',
  components: {
    CalendarPicker,
    CountriesSelectBox
  },
  mixins: [mixinPermissions, mixinNotifications],
  data: () => ({
    user: User(),
  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    newUser: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value: {
      handler() {
        this.user = Object.assign(User(), this.value)
      },
      immediate: true
    }
  },
  created() {
    this.refreshData()
  },
  computed: {
    ...mapGetters('users', ['allRoles'])
  },
  methods: {
    ...mapActions('users', ['refreshData', 'addUser', 'updateUser']),
    update() {
      let $this = this
      let user = this.user
      let userUpdate = null
      if (user.id === null) {
        userUpdate = this.addUser(user)
      } else {
        userUpdate = this.updateUser({
          user,
          id: user.id
        })
      }
      userUpdate.then(
          data => {
            $this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.users') }))
            user.id = data.id
            $this.$emit('input', user)
            $this.$root.$emit('system::refresh-tables')
          },
          ans => {
            $this.showErrorNotification($this.$t('globalMessages.addDataError', { system: $this.$t('menu.users') }), ans.error)
          }
      )

    },
    onAvatarSelected(name) {
      console.log('onAvatarSelected', name)
      this.user.avatar = '' + name
    }
  }
}
</script>

<style>

</style>
