<template>
  <v-select
      v-model="model"
      :options="countries"
      label="name"
      :reduce="(country) => country.code"
      v-bind="selectProps"
  />
</template>

<script>
export default {
  name: 'CountriesSelectBox',
  props: {
    value: {
      required: true,
    },
    selectProps:{
      default: () => ({})
    }
  },
  computed:{
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    },
    countries(){
      console.log("countries", this.$t("countries"));
      return Object.values(this.$t("countries"));
    }
  }
}
</script>

<style scoped>

</style>
