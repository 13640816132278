<template>
  <b-card no-body>
    <b-card-header class="pb-50 d-flex">
      <h5 class="d-inline-block">{{ $t('filters.title') }}</h5>
      <a class="d-inline-block" @click="refreshTables"><i class="fa-solid fa-rotate-right"></i></a>
    </b-card-header>
    <b-card-body>
      <b-row v-if="fields != null">
        <b-col cols="12" md="3" :lg="lgSize" v-for="field in _fields" v-bind:key="field.key">
          <label>{{ $t(`filters.${field.label}`) }}</label>
          <template v-if="field.type==='input'">
            <b-form-input size="sm" v-model="filter[field.key]" :debounce="filter.debounce" class="d-inline-block"/>
          </template>
          <template v-else-if="field.type==='select'">
            <v-select
                class="small"
                v-model="filter[field.key]"
                :options="field.props.options"
                :label="field.props.label"
                :reduce="field.props.reduce"
                :clearable="field.props.clearable || true"
                :multiple="field.props.multiple || false"
            />
          </template>
          <template v-else-if="field.type==='date'">
            <calendar-picker class="small" v-model="filter[field.key]" :label="$t(`filters.${field.label}`)"/>
          </template>
          <template v-else-if="field.type==='dateRange'">
            <calendar-picker-range class="small" v-model="filter[field.key]" :label="$t(`filters.${field.label}`)" v-bind="field.props"/>
          </template>
          <template v-else-if="field.type==='user'">
            <user-select-box class="small" v-model="filter[field.key]" v-bind:select-props="field.props"/>
          </template>
          <template v-else-if="field.type==='dateRange'">
            <span>TODO</span>
          </template>

        </b-col>
        <b-col cols="12" v-if="search">
          <label>{{ $t(`filters.search`) }}</label>
          <b-form-input size="sm" v-model="filter.search" :debounce="debounce" class="d-inline-block"/>
        </b-col>
      </b-row>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{$t('operations.loading')}}</b-spinner>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {parseJson} from '@/model/Utils'
import CalendarPicker from '@/components/widget/picker/calendarPicker'
import CalendarPickerRange from '@/components/widget/picker/calendarPickerRange'
import UserSelectBox from '@/components/widget/select/UserSelectBox';


export default {
  name: 'FilterCard',
  components: {  CalendarPickerRange, CalendarPicker, UserSelectBox},
  data:()=>({
    sessionKey:null,
  }),
  props: {
    value: {
      required: true,
    },
    loaded: {
      type: Boolean
    },
    filterKey: {
      required: true,
      type: String
    },
    fields: {
      required: true,
      type: Array
    },
    debounce: {
      default: () => 1500,
      type: Number
    },
    search:{
      default: () => false,
      type: Boolean
    }
  },
  watch:{
    value: {
      deep: true,
      handler(v){
        sessionStorage.setItem(this.sessionKey, JSON.stringify(v))
      }
    }
  },
  created() {
    this.sessionKey = `${this.filterKey}-filters`;
    this.initCache()
    this.$emit('loaded', true)
  },
  computed: {
    _fields() {
      let $this = this;
      return this.fields.map(field => ({
        key: field.key,
        label: field.label || field.key,
        type: field.type || 'input',
        debounce: field.debounce || $this.debounce,
        props: field.props,
        dateRange:field.dateRange
      }))
    },
    filter:{
      get(){
        return this.value
      },
      set(v){
        this.$emit("input", v)
      }
    },
    lgSize(){
      let length = (this.fields || []).length;
      if(length <= 4) return 3;
      return length % 2 === 0 || length % 3 !== 0  ? 2 : 3
    },
  },
  methods:{
    initCache(){
      this.filter = parseJson(sessionStorage.getItem(this.sessionKey), this.filter);
    },
    refreshTables(){
      this.$root.$emit('system::refresh-tables')
    },
  }
}
</script>

<style>

</style>
