<template>
  <portal to="breadcrumb-right">
    <div style="letter-spacing: 5px">
      <slot name="beforeDropdown"></slot>
      &nbsp;
      <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
      >
        <template #button-content>
          <b-button variant="primary" class="btn-icon">
            <feather-icon icon="SettingsIcon"/>
          </b-button>
        </template>
        <slot name="items"></slot>
      </b-dropdown>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'breadcrumbButton'
}
</script>

<style scoped>

</style>
