<template>
  <v-select
      v-model="model"
      :options="$store.getters['data/allUsers']"
      label="username"
      :reduce="(user) => user.id"
      v-bind="selectProps"
  />
</template>

<script>
export default {
  name: 'UserSelectBox',
  props: {
    value: {
      required: true,
    },
    selectProps:{
      default: () => ({})
    }
  },
  computed:{
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    }
  }
}
</script>

<style scoped>

</style>
