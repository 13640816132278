import Permissions from '@/model/Permissions'
import { mapGetters } from 'vuex'

export default {
  data() {
    let permissions = Permissions()
    return {
      permissions,
    }
  },
  computed: {
    ...mapGetters('user', {"_hasPermission": "hasPermission"}),
  },
  methods: {
    hasPermission(permission) {
        return this._hasPermission(permission)
    }
  }
}
