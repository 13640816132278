<template>
  <div v-bind:key="refreshKey">
    <slot name="table-top-actions" v-bind:selectedIds="selectedIds"></slot>
    <b-table responsive v-bind:items="rows" v-bind:fields="fields" no-local-sorting
             v-bind:sort-by.sync="sort.field" v-bind:sort-desc.sync="sortIsDesc"
             striped small hover @row-clicked="onRowClicked" v-bind:busy="loading"
             @sort-changed="onSortChanged"
             style="white-space: nowrap; padding-bottom: 30px"
    >

      <template #head(selection)="data">
        <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                         style="margin-top: 7px"
                         @input="val=>toggleAllRow(val)" inline></b-form-checkbox>
      </template>
      <template #cell(username)="data">
        <div class="float-left">
          <b-avatar
              size="30"
              variant="light-primary"
              :src="require(`@/assets/images/avatars/${data.item.avatar || '1'}.png`)"
          />
        </div>
        <div class="text-center">
          {{data.value}}
        </div>

      </template>
      <template #cell(selection)="data">
        <b-form-checkbox v-model="selectedIds[data.item.id]" inline @input="val=>toggleRow(data.item, val)"></b-form-checkbox>
      </template>
      <template #cell(enabled)="data">
        <p v-if="data.value"><i class="fa-solid fa-check text-success"></i> {{$t('columns.active')}}</p>
        <p v-else><i class="fa-solid fa-xmark text-warning"></i> {{$t('columns.inactive')}}</p>
      </template>
    </b-table>
    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
        </b-select>/{{ pagination.total }} {{$t('columns.counterNames.users')}}

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import mixinTable from '@/components/mixin/mixinTable'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import mixinBase from '@/components/mixin/mixinBase'

export default {
  name: 'UsersTable',
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    rows: [],
    selected:{all:false},
  }),
  props: {
    filters: {
      default: () => ({})
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1
        this.refresh()
      }
    }
  },
  created() {
    this.setPagination(1,0,10);
    this.refresh()

  },
  computed: {
    fields() {
      let $this = this;
      let columns = ["username","firstName", "lastName", "email", "roleName", "enabled"];
      return (columns || []).map(column => {
        return {
          key: column,
          label: $this.$t(`columns.${column}`),
          sortable: true,
          active: true,
        }
      });
    },
    selectedIds() {
      this.refreshKey // Little hack to recompile
      // console.log("selectedIds()",this.selected)
      return Object.keys(this.selected).filter(k=> k !== "all")
          .reduce((ans, id) => Object.assign(ans, { [id]: true }), {});
    },
  },
  methods: {
    ...mapActions('users', ['getAllUsers']),
    async refresh() {
      let $this = this;
      let loadingToken = this.setLoading();
      let sort = { ...$this.sort }
      if(sort.field == null){
        sort.field = "lastUpdate";
      }
      try{
        const result = await this.getAllUsers(
            {
              pagination: this.pagination,
              sort,
              filter: this.filters,
            }
        );
        this.rows = result.data
        this.setMetaDataFromResult(result.metadata);
        this.setLoaded(loadingToken);
      }catch (err){
        $this.showErrorNotification($this.$t('globalMessages.fetchDataError', {system:$this.$t('menu.users')}), $this.getErrorDescription(err));
      } finally {
        this.setLoaded(loadingToken);
      }
    }
  }
}
</script>

<style scoped>

</style>
