<template>
  <div>
    <v-date-picker class="inline-block h-full" :masks="masks" v-bind="$props" @dayclick="onDayClick" ref="picker">
      <template v-slot="{togglePopover,inputEvents }">
        <div class="date-picker-container flex items-center" :class="{'clearable':clearable}">
          <b-input-group :size="size" v-on="inputEvents">
            <template #prepend>
              <b-input-group-text><i class="far fa-calendar-alt"></i></b-input-group-text>
            </template>
            <b-form-input type="text" class="text-center"
                   v-bind:value="formattedTime"
                   :placeholder="i18nFormatKey"
                   @change="onDateInput"
            />
            <template #append>
              <b-input-group-text @click="clearInput"><i class="fa-solid fa-xmark"></i></b-input-group-text>
            </template>
          </b-input-group>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
export default {
  name: "calendarPicker",
  data: () => ({
    masks: {
      L: 'yyyy-dd-MM',
      input: ['yyyy-dd-MM'],
      // ...optional `title`, `weekdays`, `navMonths`, etc
    }
  }),
  props: {
    value: {
      required: true,
      default: () => null
    },
    mode: {
      required: false,
      default: () => "date"
    },
    size: {
      required: false,
      default: () => "sm"
    },
    clearable: {
      required: false,
      default: () => true
    },
    minDate: {}, maxDate: {}, disabledDates: {}, availableDates: {},
  },
  computed: {
    formattedTime() {
      let momentTime = this.$moment(this.value, this.i18nInputKey);
      if (!momentTime.isValid())
        return "";
      return momentTime.format(this.i18nFormatKey)
    },
    i18nMaskKey() {
      return this.$t('timeFormat.' + this.mode + '.mask');
    },
    i18nFormatKey() {
      return this.$t('timeFormat.' + this.mode + '.format');
    },
    i18nInputKey() {
      return this.mode === "date" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";
    },
  },
  methods: {
    setInput(val) {
      this.$emit('input', val)
    },
    clearInput() {
      this.$emit('input', null);
    },
    onDateInput(event) {
      let value = "", temp, format;
      if (typeof event === "string") {
        value = event;
        format = this.i18nInputKey;
      } else {
        value = event.target.value;
        format = this.i18nFormatKey;
      }
      if (value == null || value.trim() === "") {
        this.setInput(value);
        return;
      }
      temp = value;
      if(format !== this.i18nInputKey) {
        value = this.$options.filters.VMask(value, this.i18nMaskKey);
      }
      console.log("onDateInput", {value: temp, mask: value,format})
      let momentTime = this.$moment(value, format);
      if (!momentTime.isValid()) {
        return;
      }
      let isDisabled = this.$refs['picker'].valueIsDisabled(momentTime.toDate())
      if (isDisabled) {
        console.log("onDateInput value is disabled", {value: temp, mask: value})
        return;
      }
      this.setInput(momentTime.format(this.i18nInputKey));
    },
    onDayClick(event) {
      this.onDateInput(this.$moment(event.date).format(this.i18nInputKey));
    }
  }
}
</script>

<style lang="scss">
.date-picker-container {
  .date-picker-arrow {
    margin-right: 5px;
    margin-left: 5px;
  }
  .input-group {
    .input-group-prepend {
      .input-group-text {
        border-right: none !important;
        padding-right: 1px;
      }
    }
    input.form-control {
      border-left: none !important;
    }
  }
  &.clearable {
    .input-group-append{
      .input-group-text {
        padding-left: 1px;
        padding-right: 6px;
        cursor: pointer;
      }
    }
    input.form-control {
      border-left: none !important;
      border-right: none !important;
      padding: 0;
    }
  }
}
</style>
