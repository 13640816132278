export default function (){
  return {
    //USER_MODULE
    USER_READ_BASIC:"USER_READ_BASIC",
    USER_READ_ALL:"USER_READ_ALL",
    USER_UPDATE:"USER_UPDATE",
    //CLIENT_MODULE
    CLIENT_READ:"CLIENT_READ",
    CLIENT_READ_ALL:"CLIENT_READ_ALL",
    CLIENT_READ_CONTACT_IN_LIST:"CLIENT_READ_CONTACT_IN_LIST",
    CLIENT_READ_REPRESENTATIVE:"CLIENT_READ_REPRESENTATIVE",
    CLIENT_READ_AFFILIATE:"CLIENT_READ_AFFILIATE",
    CLIENT_UPDATE:"CLIENT_UPDATE",
    CLIENT_UPDATE_DETAILS:"CLIENT_UPDATE_DETAILS",
    CLIENT_UPDATE_BU:"CLIENT_UPDATE_BU",
    CLIENT_UPDATE_AFFILIATE:"CLIENT_UPDATE_AFFILIATE",
    CLIENT_UPDATE_ORG:"CLIENT_UPDATE_ORG",
    CLIENT_UPDATE_STATUS:"CLIENT_UPDATE_STATUS",
    CLIENT_UPDATE_REPRESENTATIVE:"CLIENT_UPDATE_REPRESENTATIVE",
    CLIENT_UPDATE_CONTACT:"CLIENT_UPDATE_CONTACT",
    OTHERS:"OTHERS",
  }
}
